<template>
  <v-card>
    <v-container class="pa-5">
      <v-card-title class="primary--text">Security</v-card-title>
      <br>
      <v-form v-model="valid" ref="form">
        <v-row>
          <v-col cols="4">
          <div class="mt-3 mr-4 text-bold">Old Password</div>
          </v-col>
          <v-col cols="8">
          <v-text-field
            :append-icon="show1 ? 'mdi-eye' : 'mdi-eye-off'"
            :rules="[rules.required, rules.min]"
            :type="show1 ? 'text' : 'password'"
            v-model="current_password"
            label="Current password"
            outlined
            color="#FF69B4"
            placeholder="Current password"
            @click:append="show1 = !show1">
          </v-text-field>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="4">
          <div class="mt-3 mr-4 text-bold">New Password</div>
          </v-col>
          <v-col cols="8">
          <v-text-field
            :append-icon="show2 ? 'mdi-eye' : 'mdi-eye-off'"
            :rules="[rules.required, rules.min]"
            :type="show2 ? 'text' : 'password'"
            v-model="new_password"
            label="New password"
            outlined
            color="#FF69B4"
            placeholder="New password"
            @click:append="show2 = !show2">
          </v-text-field>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="4">
          <div class="mt-3 mr-4 text-bold">Confirm Password</div>
          </v-col>
          <v-col cols="8">
          <v-text-field
            :append-icon="show3 ? 'mdi-eye' : 'mdi-eye-off'"
            :rules="[rules.required, rules.min, rules.match]"
            :type="show3 ? 'text' : 'password'"
            v-model="confirm_new_password"
            label="Confirm new password"
            outlined
            color="#FF69B4"
            placeholder="Confirm password"
            @click:append="show3 = !show3">
          </v-text-field>
          </v-col>
        </v-row>
        <v-row>
        <div class="mx-auto">
          <v-btn :loading="updatePasswordLoad" class="mb-5" height="35" @click="change_password"
                 color="primary white--text">
            <span class="text-center">Submit</span>
          </v-btn>
        </div>
        </v-row>
      </v-form>
    </v-container>
  </v-card>
</template>

<script>
import user from '@/web_services/user'
import { mapState } from 'vuex'

export default {
  data () {
    return {
      valid: true,
      current_password: '',
      new_password: '',
      updatePasswordLoad: false,
      confirm_new_password: '',
      show1: false,
      show2: false,
      show3: false,
      rules: {
        required: value => !!value || 'This field Required.',
        min: v => v.length >= 8 || 'Minimum is 8 characters',
        match: v => this.new_password === this.confirm_new_password || 'Password and confirmation don\'t match'
      }
    }
  },
  methods: {
    change_password () {
      if (this.$refs.form.validate()) {
        this.updatePasswordLoad = true
        const Data = {
          current_password: this.current_password,
          new_password: this.new_password,
          confirm_new_password: this.confirm_new_password
        }
        user.update(Data).then(() => {
          this.updatePasswordLoad = false
          this.$emit('success', 'Password update successfully')
        }).catch(() => {
          this.$emit('error', 'Credentials error!')
          this.updatePasswordLoad = false
        }).finally(() => {
          this.$router.push('/profile/about')
        })
      }
    }
  },
  computed: {
    ...mapState(['user'])
  }
}

</script>

<style scoped>
</style>
